<script setup>
import { storeToRefs } from 'pinia';
import Illustration from 'assets/illustrations/illu-07.svg';
import Arrow from 'assets/illustrations/illu_spark_01.svg';
import { useMessageStore } from '~/stores/messages';

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

// Markdown
const { $md } = useNuxtApp();
const pageSubline1 = staticKeys.value?.center_selector_teaser_headline_web_markdown ? $md.renderInline(staticKeys.value?.center_selector_teaser_headline_web_markdown) : '';
const pageSubline2 = staticKeys.value?.parking_card_teaser_headline_web_markdown ? $md.renderInline(staticKeys.value?.parking_card_teaser_headline_web_markdown) : '';
const actionTxt = staticKeys.value?.center_selector_action_txt_web ? $md.renderInline(staticKeys.value?.center_selector_action_txt_web) : '';

const buttonConfig = {
    buttonType: 'primary',
    type: 'internal',
    link: staticKeys.value?.parking_card_btn_link_web,
    active: true
};
</script>

<template>
    <section class="hero-teaser">
        <div class="hero-teaser-container">
            <div class="center-selector-teaser-wrapper">
                <div class="center-selector-teaser-container">
                    <h2 v-html="pageSubline1" />
                    <p>{{ staticKeys?.center_selector_teaser_txt_web }}</p>

                    <div class="center-selector">
                        <CenterSelector :position="'content'" />
                    </div>
                </div>
            </div>

            <div class="center-selector-action">
                <Arrow />
                <h3 v-html="actionTxt" />
            </div>

            <div class="hero-teaser-info-txt">
                <h2 v-html="pageSubline2" />
                <p>{{ staticKeys?.parking_card_teaser_txt_web }}</p>

                <div class="hero-teaser-info-txt-btn-bar">
                    <UiButton :config="buttonConfig">
                        {{ staticKeys?.parking_card_btn_web }}
                    </UiButton>
                </div>
            </div>

            <div class="hero-teaser-info-media">
                <Illustration />
            </div>
        </div>
    </section>
</template>

<style lang="scss">
.hero-teaser {
    @include fullbleed;

    position: relative;
    z-index: z('center-selector-teaser');

    @include bp(large1) {
        background: $gradient1;
        padding: 80px 0;

        .start-page-container & {
            background: transparent;
        }
    }
}

.hero-teaser-container {
    @include content-grid;
}

.center-selector-teaser-wrapper {
    grid-column: 1/ span 5;
    grid-row: 1;
    background: $gradient1;
    margin: 0 -4vw;

    @include bp(medium1) {
        grid-column: 1/ span 8;
    }

    @include bp(large1) {
        grid-column: 1/ span 4;
        background: none;
        margin: 0;
    }
}

.center-selector-teaser-container {
    @include content-grid;

    margin: 0 4vw;
    padding: 40px 0 30px;

    @include bp(medium1) {
        padding: 80px 0 50px;
    }

    @include bp(large1) {
        margin: 0;
        padding: 0;
        height: 100%;
        display: flex;
        flex-flow: row wrap;
    }

    h2 {
        @include superheadline-h2;

        grid-column: 1/ span 5;
        margin: 0 0 10px;

        @include bp(medium1) {
            grid-column: 1/ span 4;
            margin: 0 0 4px;
        }

        @include bp(large1) {
            max-width: 100%;
            width: 100%;
            margin: 0;
        }
    }

    p {
        @include body1-medium;

        margin: 0 0 30px;
        grid-column: 1/ span 5;

        @include bp(medium1) {
            grid-column: 1/ span 4;
            margin: 0 0 30px;
        }

        @include bp(large1) {
            max-width: 100%;
            width: 100%;
            margin: 0;
        }
    }
}

.center-selector-action {
    display: none;

    @include bp(medium1) {
        grid-column: 5/ span 3;
        grid-row: 1;
        height: 100%;
        display: flex;
        align-items: flex-end;
        padding: 80px 0 50px;
    }

    @include bp(large1) {
        padding: 0;
    }

    svg {
        width: 52px;
        height: 34px;
        margin-right: 10px;
        fill: $body-text;

        @include bp(large1) {
            width: 64px;
            height: 42px;
        }
    }

    h3 {
        font-family: $font-headline;
        font-weight: 500;
        font-style: italic;
        font-size: px2rem(30);
        line-height: px2rem(36);
        letter-spacing: -0.5px;
        margin: 0;

        @include bp(medium1) {
            font-size: px2rem(20);
            line-height: px2rem(24);
        }

        @include bp(large1) {
            font-size: px2rem(26);
            line-height: px2rem(32);
        }
    }
}

.center-selector {
    grid-column: 1/ span 5;
    margin-top: auto;
    width: 100%;

    @include bp(medium1) {
        grid-column: 1/ span 4;

    }
}

.hero-teaser-info-txt {
    grid-column: 1/ span 5;
    grid-row: 3;
    padding: 0 0 50px;
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: row wrap;

    @include bp(medium1) {
        grid-column: 5/ span 4;
        grid-row: 2;
        padding: 60px 0;
    }

    @include bp(large1) {
        grid-column: 9/ span 4;
        grid-row: 1;
        margin: 0;
        padding: 0;
    }

    h2 {
        @include superheadline-h2;
    }

    p {
        @include body1-medium;

        margin: 0 0 30px;

        @include bp(medium1) {
            margin: 0 0 50px;
        }

        @include bp(large1) {
            margin: 0 0 60px;
        }
    }
}

.hero-teaser-info-txt-btn-bar {
    margin: auto;

    @include bp(medium1) {
        margin: auto 0 0;
    }
}

.hero-teaser-info-media {
    grid-column: 1/ span 5;
    grid-row: 2;
    padding: 50px 0 0;

    @include bp(medium1) {
        grid-column: 1/ span 4;
        grid-row: 2;
        padding: 60px 0;
    }

    @include bp(large1) {
        display: none;
    }
}
</style>
